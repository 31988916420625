import React from "react"
import NextLink from "next/link"
import cn from "classnames"
import Icon, { IconType } from "../icon/Icon"
import { useSettings } from "../../layout/page/settingsContext"

interface LinkProps {
    children: React.ReactNode
    href: string
    locale?: string | false
    target?: string
    className?: string
    appearance?: "secondary" | "dark" | "light"
    rel?: string
    style?: React.CSSProperties
    arrow?: boolean
    markAsNew?: boolean
    pill?: boolean
    prefetch?: boolean
    onClick?: (any) => void
    handleOnPointerEnter?: () => void
    handleOnPointerLeave?: () => void
    onMouseDown?: (any) => void
}

export const Link = ({ pill = false, arrow = false, markAsNew = false, children, href, locale, target, rel, handleOnPointerEnter, handleOnPointerLeave, onMouseDown, prefetch, appearance, ...props }: LinkProps) => {
    const { translations: t } = useSettings()

    // NextLink does not support hash links, so we use a normal anchor tag instead
    // https://github.com/vercel/next.js/issues/44295
    const LinkComponent = prefetch === false || href?.startsWith("#") ? "a" : NextLink
    return (
        <LinkComponent
            {...(LinkComponent === "a" ? {} : { scroll: true, prefetch: prefetch, locale: locale })}
            href={href || ""}
            target={target}
            rel={rel}
            {...(href?.includes("https://support.lunar.app") ? { referrerPolicy: "no-referrer-when-downgrade" } : {})}
            {...props}
            className={cn(props.className, {
                "button--pill": pill,
                "arrow-link--right": arrow,
                [`button--${appearance}`]: appearance,
            })}
            onPointerEnter={handleOnPointerEnter}
            onPointerLeave={handleOnPointerLeave}
            onMouseDown={onMouseDown}
        >
            <>
                {children} {arrow ? <Icon type={IconType.ARROW_RIGHT} /> : null}
                {markAsNew && <p className={cn("label-200", "bg-accent")}>{t.header.markAsNew}</p>}
            </>
        </LinkComponent>
    )
}
